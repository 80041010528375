import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import HeroImage from "../assets/hero-illustration.png";
import Button from "./Button";

export default function Hero({ darkMode }) {
  const [collectibles, setCollectibles] = useState("");
  const [auctions, setAuctions] = useState("");
  const [artists, setArtists] = useState("");
  const [t] = useTranslation("common");

  // const getHeroInfos = () => {
  //   axios
  //     .get("https://api.whalemusic.com/stats")
  //     .then((res) => {
  //       setCollectibles(res.collectibles);
  //       setAuctions(res.auctions);
  //       setArtists(res.artists);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  // useEffect(() => {
  //   getHeroInfos();
  // }, []);

  return (
    <section id="hero">
      <div
        className={`${
          darkMode ? "text-white bg-[#160F48]" : "text-black bg-slate-300"
        } py-20 md:py-24`}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 container mx-auto">
          <div
            className="flex flex-col gap-8 text-center items-center md:text-left md:items-start"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <h1 className="text-5xl text-center md:text-left md:text-7xl font-semibold">
              {t("hero.slogan")}
            </h1>
            <p className="md:text-xl">{t("hero.intro")}</p>
            <div className="flex justify-between w-3/4">
              <div className="text-center">
                <h1 className="text-2xl md:text-3xl font-semibold">35k+</h1>
                <p className="text-sm md:text-base">{t("explore.data1")}</p>
              </div>
              <div className="text-center">
                <h1 className="text-2xl md:text-3xl font-semibold">15k+</h1>
                <p className="text-sm md:text-base">{t("explore.data2")}</p>
              </div>
              <div className="text-center">
                <h1 className="text-2xl md:text-3xl font-semibold">5k+</h1>
                <p className="text-sm md:text-base">{t("explore.data3")}</p>
              </div>
            </div>
            <div className="pt-5">
              <Button text={t("explore.data4")} size="lg" variant="color" />
            </div>
          </div>
          <div
            className="hidden md:block"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <img src={HeroImage} alt="hero illustration" />
          </div>
        </div>
      </div>
    </section>
  );
}

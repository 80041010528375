import React from "react";
import { useTranslation } from "react-i18next";

export default function Community({ darkMode }) {
  const [t] = useTranslation("common");
  return (
    <div
      className={`${
        darkMode ? "text-white bg-[#160F48]" : "text-black bg-slate-300"
      } py-10 md:py-32`}
    >
      <div className="mx-3">
        <div
          className={`${
            darkMode
              ? "bg-[#D9D9D933]/20 border-white text-white"
              : "bg-slate-200 border-black text-black"
          } container mx-auto py-4 md:py-16 px-3 md:px-0 rounded-lg flex flex-col items-center gap-6`}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <h1 className="font-bold text-2xl md:text-6xl text-center">
            {t("community.title")}
          </h1>
          <p className="text-center text-base md:text-xl">
            {t("community.description")}
          </p>
          <button className="bg-gradient-to-r from-[#0090FF] to-[#CE00FF] hover:bg-transparent dark:text-white text-md md:text-lg px-6 py-3 rounded-lg font-md transition-all flex items-center gap-2">
            {t("community.discord")}
          </button>
        </div>
      </div>
    </div>
  );
}

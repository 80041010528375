import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import Vector from "../assets/Vector.png";
import { HTTPRequest } from "../api/featherConfig";
const data = require("../data/data.json");

const Collection = ({ darkMode }) => {
  const [filter, setFilter] = useState([0, 0, 0, 0, 0]);
  const [t] = useTranslation("common");

  const getCollections = async () => {
    await HTTPRequest("find", "projects");
  };

  useEffect(() => {
    getCollections();
  }, []);

  const handleClick = (idx) => {
    var tmp = [0, 0, 0, 0, 0];
    tmp[idx] = filter[idx] ? 0 : 1;
    setFilter(tmp);
  };

  return (
    <section
      id="marketplace"
      className={` ${
        darkMode ? "text-white bg-[#160F48]" : "text-black bg-slate-300"
      } h-full w-full min-h-screen flex flex-col pt-5 pb-10`}
    >
      <div className="w-1/3 h-1/6 md:pl-20 pl-2">
        <p className="text-4xl">Marketplace</p>
      </div>
      <div className="w-full h-full flex flex-col">
        <div className="flex flex-row md:pl-20 pl-2 pt-5">
          <img height={"24px"} width={"17px"} src={Vector} alt="flame" />
          <p className="pl-2">{t("collection.data1")}</p>
        </div>
        <div className="w-full pt-5 flex flex-row justify-evenly md:px-10 space-x-3 px-1">
          <div className="w-1/5 md:h-auto h-[10%] flex justify-center items-center">
            <motion.div
              data-testid="handle"
              onClick={() => {
                handleClick(0);
              }}
              whileHover={{
                scale: 1.2,
              }}
              className={
                !filter[0]
                  ? `h-3/4 md:w-1/2 w-full flex cursor-pointer justify-center items-center rounded-lg py-3 ${
                      darkMode ? "bg-[#30384D]" : "bg-white"
                    } `
                  : "h-3/4 md:w-1/2 w-full flex cursor-pointer justify-center items-center rounded-lg py-3 bg-gradient-to-r from-[#0090FF] to-[#CE00FF]"
              }
            >
              <p>Rap</p>
            </motion.div>
          </div>
          <div className="w-1/5 flex justify-center items-center md:h-auto h-[10%]">
            <motion.div
              data-testid="handle1"
              onClick={() => {
                handleClick(1);
              }}
              whileHover={{
                scale: 1.2,
              }}
              className={
                !filter[1]
                  ? `h-3/4 md:w-1/2 w-full flex cursor-pointer justify-center items-center rounded-lg py-3 ${
                      darkMode ? "bg-[#30384D]" : "bg-white"
                    } `
                  : "h-3/4 md:w-1/2 w-full flex cursor-pointer justify-center items-center rounded-lg py-3 bg-gradient-to-r from-[#0090FF] to-[#CE00FF]"
              }
            >
              <p>Classic</p>
            </motion.div>
          </div>
          <div className="w-1/5 flex justify-center items-center md:h-auto h-[10%]">
            <motion.div
              data-testid="EDM"
              onClick={() => {
                handleClick(2);
              }}
              whileHover={{
                scale: 1.2,
              }}
              className={
                !filter[2]
                  ? `h-3/4 md:w-1/2 w-full flex cursor-pointer justify-center items-center rounded-lg py-3 ${
                      darkMode ? "bg-[#30384D]" : "bg-white"
                    } `
                  : "h-3/4 md:w-1/2 w-full flex cursor-pointer justify-center items-center rounded-lg py-3 bg-gradient-to-r from-[#0090FF] to-[#CE00FF]"
              }
            >
              <p>EDM</p>
            </motion.div>
          </div>
          <div className="w-1/5 flex justify-center items-center md:h-auto h-[10%]">
            <motion.div
              data-testid="Pop"
              onClick={() => {
                handleClick(3);
              }}
              whileHover={{
                scale: 1.2,
              }}
              className={
                !filter[3]
                  ? `h-3/4 md:w-1/2 w-full flex cursor-pointer justify-center items-center rounded-lg py-3 ${
                      darkMode ? "bg-[#30384D]" : "bg-white"
                    } `
                  : "h-3/4 md:w-1/2 w-full flex cursor-pointer justify-center items-center rounded-lg py-3 bg-gradient-to-r from-[#0090FF] to-[#CE00FF]"
              }
            >
              <p>Pop&rock</p>
            </motion.div>
          </div>
          <div className="w-1/5 flex justify-center items-center md:h-auto h-[10%]">
            <motion.div
              data-testid="HipHop"
              onClick={() => {
                handleClick(4);
              }}
              whileHover={{
                scale: 1.2,
              }}
              className={
                !filter[4]
                  ? `h-3/4 md:w-1/2 w-full flex cursor-pointer justify-center items-center rounded-lg py-3 ${
                      darkMode ? "bg-[#30384D]" : "bg-white"
                    } `
                  : "h-3/4 md:w-1/2 w-full flex cursor-pointer justify-center items-center rounded-lg py-3 bg-gradient-to-r from-[#0090FF] to-[#CE00FF]"
              }
            >
              <p>HipHop</p>
            </motion.div>
          </div>
        </div>
        <div className="w-full flex flex-col justify-center items-center py-10 ">
          <div className="w-3/4 grid grid-cols-1 lg:grid-cols-3  gap-8 justify-items-center">
            {data?.map((elem) => {
              var genre = ["Rap", "Classic", "EDM", "Pop", "HipHop"];
              var style = filter.findIndex((element) => element === 1);
              if (style >= 0) {
                if (elem.genre.includes(genre[style])) {
                  return (
                    <div
                      key={elem.id}
                      className={` ${
                        darkMode
                          ? "bg-[#D9D9D933]/20 border-white "
                          : "border-black bg-slate-200"
                      } h-full w-3/4 flex flex-col justify-center items-center rounded-xl my-5 border-4 `}
                    >
                      <div className="w-3/4 flex justify-center items-center flex-col">
                        <img
                          height={"250px"}
                          width={"220px"}
                          src={elem.cover}
                          className="rounded-xl pt-2"
                          alt="cover"
                        />
                      </div>
                      <div className="w-full pt-4">
                        <p className="text-xl pl-4">{elem.name}</p>
                        <p className="text-xs pl-4">{elem.label}</p>
                        <div className="w-full flex flex-row items-center justify-between px-4 pt-4">
                          <p>{elem.price}</p>
                          <p className="text-xs">
                            {t("collection.fin_enchere")} {elem.date}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                }
              }
              return (
                <div
                  key={elem.id}
                  className={` ${
                    darkMode
                      ? "bg-[#D9D9D933]/20 border-white "
                      : "border-black bg-slate-200"
                  } h-full w-3/4 flex flex-col justify-center items-center rounded-xl my-5 border-4 `}
                >
                  <div className="w-3/4 flex justify-center items-center flex-col">
                    <img
                      height={"250px"}
                      width={"220px"}
                      src={elem.cover}
                      className="rounded-xl pt-2"
                      alt="cover"
                    />
                  </div>
                  <div className="w-full pt-4">
                    <p className="text-xl pl-4">{elem.name}</p>
                    <p className="text-xs pl-4">{elem.label}</p>
                    <div className="w-full flex flex-row items-center justify-between px-4 pt-4">
                      <p>{elem.price}</p>
                      <p className="text-xs">
                        {t("collection.fin_enchere")} {elem.date}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="w-full flex justify-center items-center pt-20">
            <motion.div
              // onClick={() => }
              whileHover={{
                scale: 1.1,
              }}
              className={` ${
                darkMode
                  ? "bg-gradient-to-r from-[#0090FF] to-[#CE00FF]"
                  : "bg-white"
              } h-full md:w-[10%] w-2/3 flex justify-center items-center py-3 rounded-xl cursor-pointer`}
            >
              <p>{t("collection.see_more")}</p>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Collection;

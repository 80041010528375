import "./App.css";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import NavBar from "./components/Navbar";
import Collection from "./components/Collection";
import Hero from "./components/Hero";
import Footer from "./components/Footer";
import Community from "./components/Community";
import Steps from "./components/Steps";
import Roadmap from "./components/Roadmap";

function App() {
  const { t } = useTranslation("common");
  const [isOn, setIsOn] = useState(true);
  return (
    <div className="h-auto">
      <NavBar darkMode={isOn} funcDarkMode={setIsOn} />
      <Hero darkMode={isOn} />
      <Collection darkMode={isOn} />
      <Steps darkMode={isOn} />
      <Roadmap darkMode={isOn} />
      <Community darkMode={isOn} />
      <Footer darkMode={isOn} />
      <ToastContainer />
    </div>
  );
}

export default App;

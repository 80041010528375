import React, { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { AnimatePresence, motion } from "framer-motion";
import { BsFillSunFill, BsMoonStarsFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { MdMenu, MdMenuOpen } from "react-icons/md";
import Modal from "./Modal";

import Logo from "../assets/whale-music.png";
// import SamplePDF from "../401.pdf";

const NavBar = ({ darkMode, funcDarkMode }) => {
  const [selected, setSelected] = useState("GB");
  const [t, i18n] = useTranslation("common");
  const [openMenu, setOpenMenu] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const close = () => {
    setShowModal(false);
  };

  const changeLangue = (code) => {
    setSelected(code);
    if (code === "FR") {
      i18n.changeLanguage("fr");
    } else {
      i18n.changeLanguage("en");
    }
  };

  return (
    <>
      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {showModal && <Modal onClose={close} />}
      </AnimatePresence>
      <div
        className={` ${
          darkMode ? "bg-[#160F48] text-white" : "bg-slate-300 text-black"
        } h-1/5 w-full md:flex flex-row justify-center items-center hidden`}
      >
        <a
          href="/"
          className="h-full w-1/6 flex flex-col items-center justify-center py-4"
        >
          <img height={"70px"} width={"70px"} src={Logo} alt="logo" />
        </a>
        <div className="h-full w-2/3 flex flex-row items-center justify-center">
          <div className="h-3/4 w-3/4 flex flex-row items-center justify-around">
            <a href="#marketplace">{t("navbar.button1")}</a>
            <a href="#steps">{t("navbar.button2")}</a>
            <a href="#roadmap">{t("navbar.button4")}</a>
            <a href={"401.pdf"} target="_blank" rel="noreferrer">
              {t("navbar.button3")}
            </a>
            <ReactFlagsSelect
              selected={selected}
              onSelect={(code) => changeLangue(code)}
              countries={["GB", "FR"]}
              showSelectedLabel={false}
              showOptionLabel={false}
            />
          </div>
          <motion.div
            data-testid="darkmode"
            onClick={() => funcDarkMode(!darkMode)}
            whileHover={{
              scale: 1.1,
            }}
            className={
              !darkMode
                ? "bg-slate-400 h-full w-[5%] flex justify-center items-center rounded-lg cursor-pointer"
                : "bg-slate-200 h-full w-[5%] flex justify-center items-center rounded-lg cursor-pointer"
            }
          >
            {!darkMode ? (
              <BsFillSunFill color="yellow" className="h-full w-1/2 m-2" />
            ) : (
              <BsMoonStarsFill color="black" className="h-full w-1/2 m-2" />
            )}
          </motion.div>
        </div>
        <div className="h-full w-1/6 flex justify-center items-center">
          <motion.div
            data-testid="modal"
            onClick={() => openModal()}
            whileHover={{
              scale: 1.1,
            }}
            className={` ${
              !darkMode && "border-black bg-slate-100"
            } h-1/3 w-[45%] flex justify-center items-center border-2 rounded-lg cursor-pointer py-2`}
          >
            <p>{t("navbar.button5")}</p>
          </motion.div>
        </div>
      </div>
      <div
        className={` ${
          darkMode ? "bg-[#160F48] text-white" : "bg-slate-300 text-black"
        } h-1/5 w-full flex md:hidden flex-rows justify-around items-center`}
      >
        <div className="h-full w-1/5 flex flex-col items-center justify-center py-4">
          <img height={"70px"} width={"70px"} src={Logo} alt="logo" />
        </div>
        <div
          onClick={() => setOpenMenu(!openMenu)}
          className="h-full w-1/5 flex justify-center items-center"
        >
          {openMenu ? <MdMenu size={"40px"} /> : <MdMenuOpen size={"40px"} />}
        </div>
        <div className="h-full w-1/5 flex justify-center items-center">
          <ReactFlagsSelect
            selected={selected}
            onSelect={(code) => changeLangue(code)}
            countries={["GB", "FR"]}
            showSelectedLabel={false}
            showOptionLabel={false}
          />
        </div>
        <div className="h-full w-1/5">
          <motion.div
            onClick={() => funcDarkMode(!darkMode)}
            whileHover={{
              scale: 1.1,
            }}
            className={
              !darkMode
                ? "bg-slate-400 h-full w-[50%] flex justify-center items-center rounded-lg cursor-pointer"
                : "bg-slate-200 h-full w-[50%] flex justify-center items-center rounded-lg cursor-pointer"
            }
          >
            {!darkMode ? (
              <BsFillSunFill color="yellow" className="h-full w-1/2 m-2" />
            ) : (
              <BsMoonStarsFill color="black" className="h-full w-1/2 m-2" />
            )}
          </motion.div>
        </div>
        <div className="h-full w-1/5">
          <motion.div
            whileHover={{
              scale: 1.1,
            }}
            className={` ${
              !darkMode && "border-black bg-slate-100"
            } h-1/3 w-2/3 flex justify-center items-center border-2 rounded-lg cursor-pointer py-2`}
          >
            <p>Login</p>
          </motion.div>
        </div>
      </div>
      {!openMenu && (
        <div
          className={`absolute flex md:hidden flex-col ${
            !darkMode ? "bg-slate-200" : "bg-[#160F48] text-white"
          } h-32 w-full justify-center pl-5 space-y-2`}
        >
          <a href="#marketplace">{t("navbar.button1")}</a>
          <a href="#steps">{t("navbar.button2")}</a>
          <a href="#roadmap">{t("navbar.button4")}</a>
          <a href={"401.pdf"} target="_blank" rel="noreferrer">
            {t("navbar.button3")}
          </a>
        </div>
      )}
    </>
  );
};

export default NavBar;

import React from "react";
import { useTranslation } from "react-i18next";

import Money from "../assets/money.png";
import Music from "../assets/music.png";
import Shield from "../assets/shield.png";

export default function Steps({ darkMode }) {
  const [t] = useTranslation("common");
  return (
    <section id="steps" className={darkMode ? "bg-[#160F48]" : "bg-slate-300"}>
      <div
        className={
          darkMode
            ? "container mx-auto py-10 md:py-20 px-3 md:px-0 bg-[#160F48] text-white"
            : "container mx-auto py-10 md:py-20 px-3 md:px-0 bg-slate-300 text-black"
        }
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <h1 className="font-bold text-3xl md:text-6xl text-center mb-24 md:mg-8">
          {t("steps.title")}
        </h1>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-3 md:gap-14 py-8">
          <div
            className={
              darkMode
                ? "bg-[#D9D9D933]/20 text-center rounded-lg w-full p-2 md:p-10 flex flex-col items-center gap-3 cursor-pointer hover:scale-105 transition-all"
                : "bg-slate-200 text-center rounded-lg w-full p-2 md:p-10 flex flex-col items-center gap-3 cursor-pointer hover:scale-105 transition-all"
            }
          >
            <img src={Music} alt="" className="w-20 my-8" />
            <h1 className="text-2xl md:text-3xl font-bold">
              {t("steps.step1_title")}
            </h1>
            <p className="text-sm md:text-base">
              {t("steps.step1_description")}
            </p>
          </div>
          <div
            className={
              darkMode
                ? "bg-[#D9D9D933]/20 text-center rounded-lg w-full p-2 md:p-10 flex flex-col items-center gap-3 cursor-pointer hover:scale-105 transition-all"
                : "bg-slate-200 text-center rounded-lg w-full p-2 md:p-10 flex flex-col items-center gap-3 cursor-pointer hover:scale-105 transition-all"
            }
          >
            <img src={Shield} alt="" className="w-16 my-8" />
            <h1 className="text-2xl md:text-3xl font-bold">
              {t("steps.step2_title")}
            </h1>
            <p className="text-sm md:text-base">
              {t("steps.step2_description")}
            </p>
          </div>
          <div
            className={
              darkMode
                ? "bg-[#D9D9D933]/20 text-center rounded-lg w-full p-2 md:p-10 flex flex-col items-center gap-3 cursor-pointer hover:scale-105 transition-all"
                : "bg-slate-200 text-center rounded-lg w-full p-2 md:p-10 flex flex-col items-center gap-3 cursor-pointer hover:scale-105 transition-all"
            }
          >
            <img src={Money} alt="" className="w-35 my-8" />
            <h1 className="text-2xl md:text-3xl font-bold">
              {t("steps.step3_title")}
            </h1>
            <p className="text-sm md:text-base">
              {t("steps.step3_description")}
            </p>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center items-center py-10 md:pb-32">
        <button className="bg-gradient-to-r from-[#0090FF] to-[#CE00FF] hover:bg-transparent dark:text-white text-md md:text-lg px-6 py-3 rounded-lg font-md transition-all flex items-center gap-2">
          {t("steps.button_title")}
        </button>
      </div>
    </section>
  );
}

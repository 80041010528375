import React from "react";
import { useTranslation } from "react-i18next";

export default function Roadmap({ darkMode }) {
  const [t] = useTranslation("common");
  return (
    <section id="roadmap">
      <div className={darkMode ? "bg-[#160F48] pt-10" : "bg-slate-300 pt-10"}>
        <div
          className={
            darkMode
              ? "container mx-auto w-full h-full text-white"
              : "container mx-auto w-full h-full text-black"
          }
        >
          <h1 className="font-bold text-3xl md:text-6xl text-center mb-10 md:mg-8">
            {t("roadmap.title")}
          </h1>
          <p className="text-center mb-10 px-10">{t("roadmap.intro")}</p>
          <div className="relative wrap overflow-hidden p-10 h-full">
            <div
              className={
                darkMode
                  ? "border-2-2 absolute border-opacity-20 bg-slate-300 h-full border"
                  : "border-2-2 absolute border-opacity-80 bg-slate-700 h-full border"
              }
              style={{ left: "50%" }}
            ></div>
            {/* <!-- right timeline --> */}
            <div className="mb-8 flex justify-between items-center w-full right-timeline">
              <div className="order-1 w-5/12"></div>
              <div className="z-20 flex items-center order-1 bg-slate-500 shadow-xl w-8 h-8 rounded-full">
                <h1 className="mx-auto font-semibold text-lg text-white">1</h1>
              </div>
              <div
                className={
                  darkMode
                    ? "order-1 bg-[#D9D9D933]/20 rounded-lg shadow-xl w-5/12 px-6 py-4"
                    : "order-1 bg-slate-200 rounded-lg shadow-xl w-5/12 px-6 py-4"
                }
              >
                <h3
                  className={
                    darkMode
                      ? "mb-3 font-bold text-gray-200 text-xl"
                      : "mb-3 font-bold text-gray-800 text-xl"
                  }
                >
                  Phase 1
                </h3>
                <p
                  className={
                    darkMode
                      ? "text-sm leading-snug tracking-wide text-gray-100 text-opacity-100 break-words"
                      : "text-sm leading-snug tracking-wide text-gray-900 text-opacity-100 break-words"
                  }
                >
                  {t("roadmap.step1")}
                </p>
              </div>
            </div>

            {/* <!-- left timeline --> */}
            <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
              <div className="order-1 w-5/12"></div>
              <div className="z-20 flex items-center order-1 bg-slate-500 shadow-xl w-8 h-8 rounded-full">
                <h1 className="mx-auto text-white font-semibold text-lg">2</h1>
              </div>
              <div className="order-1 bg-[#0090FF] rounded-lg shadow-xl w-5/12 px-6 py-4">
                <h3 className="mb-3 font-bold text-white text-xl">Phase 2</h3>
                <p className="text-sm font-medium leading-snug tracking-wide text-white text-opacity-100 break-words">
                  {t("roadmap.step2")}
                </p>
              </div>
            </div>

            {/* <!-- right timeline --> */}
            <div className="mb-8 flex justify-between items-center w-full right-timeline">
              <div className="order-1 w-5/12"></div>
              <div className="z-20 flex items-center order-1 bg-slate-500 shadow-xl w-8 h-8 rounded-full">
                <h1 className="mx-auto font-semibold text-lg text-white">3</h1>
              </div>
              <div
                className={
                  darkMode
                    ? "order-1 bg-[#D9D9D933]/20 rounded-lg shadow-xl w-5/12 px-6 py-4"
                    : "order-1 bg-slate-200 rounded-lg shadow-xl w-5/12 px-6 py-4"
                }
              >
                <h3
                  className={
                    darkMode
                      ? "mb-3 font-bold text-gray-200 text-xl"
                      : "mb-3 font-bold text-gray-800 text-xl"
                  }
                >
                  Phase 3
                </h3>
                <p
                  className={
                    darkMode
                      ? "text-sm leading-snug tracking-wide text-gray-100 text-opacity-100 break-words"
                      : "text-sm leading-snug tracking-wide text-gray-900 text-opacity-100 break-words"
                  }
                >
                  {t("roadmap.step3")}
                </p>
              </div>
            </div>

            {/* <!-- left timeline --> */}
            <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
              <div className="order-1 w-5/12"></div>
              <div className="z-20 flex items-center order-1 bg-slate-500 shadow-xl w-8 h-8 rounded-full">
                <h1 className="mx-auto text-white font-semibold text-lg">4</h1>
              </div>
              <div className="order-1 bg-[#0090FF] rounded-lg shadow-xl w-5/12 px-6 py-4">
                <h3 className="mb-3 font-bold text-white text-xl">Phase 4</h3>
                <p className="text-sm font-medium leading-snug tracking-wide text-white text-opacity-100 break-words">
                  {t("roadmap.step4")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

import Logo from "../assets/whale-music.png";

const Backdrop = ({ children, onClick }) => {
  return (
    <motion.div
      className="bg-gray-300 fixed h-screen w-full z-50 bg-opacity-50 top-0 left-0 flex justify-center items-center"
      onClick={onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  );
};

const animateModal = {
  hidden: {
    opacity: 1,
    scale: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.4,
    },
    scale: 1,
  },
  exit: {
    opacity: 1,
    scale: 0,
    transition: {
      duration: 0.15,
    },
  },
};

function Modal({ onClose }) {
  const handleRedirection = (url) => {
    window.location.href = url;
  };

  return (
    <Backdrop onClick={onClose}>
      <motion.div
        className="w-2/3 h-4/5 rounded-2xl shadow-xl flex flex-col text-white"
        onClick={(e) => e.stopPropagation()}
        variants={animateModal}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {/* bg-gradient-to-r  from-[#0090FF] to-[#CE00FF]  */}
        <div className="w-full h-screen bg-[#160F48] flex flex-cols rounded-3xl border-2 border-white">
          <div className="w-full h-full flex items-center justify-center">
            <div className="h-3/4 w-3/4  flex items-center justify-center flex-col">
              <motion.div
                whileHover={{
                  scale: 1.1,
                }}
                className="h-[15%] w-1/2 flex justify-around items-center flex-col rounded-lg bg-[#0090FF] border-white border-2 hover:cursor-pointer"
                onClick={handleRedirection("https://invest.whalemusic.fr/")}
              >
                <p className="font-bold text-lg">Investissement</p>
                {/* <motion.div
                  whileHover={{
                    scale: 1.2,
                  }}
                  animate={{
                    backgroundColor: isHoverI ? "black" : "white",
                  }}
                  onHoverStart={() => setHoverI(true)}
                  onHoverEnd={() => setHoverI(false)}
                  className="w-[40%] h-1/4 flex justify-center items-center border-2 border-black rounded-lg cursor-pointer hover:text-white hover:border-white"
                >
                  <p>Login</p>
                </motion.div> */}
              </motion.div>
            </div>
          </div>
          <div className="h-ful flex justify-center items-center flex-col">
            <div className="h-1/2 w-2 bg-white" />
            <div className="w-32 rounded-[50%] flex justify-center bg-white">
              <img height={"100%"} width={"100%"} src={Logo} alt="logo" />
            </div>
            <div className="h-1/2 w-2 bg-white" />
          </div>
          <div className="w-full h-full flex items-center justify-center">
            <div className="h-3/4 w-2/3  flex items-center justify-center flex-col">
              <motion.div
                whileHover={{
                  scale: 1.1,
                }}
                className="h-[15%] w-1/2 flex justify-around items-center flex-col rounded-lg bg-[#CE00FF] border-white border-2 hover:cursor-pointer"
                onClick={handleRedirection("https://market.whalemusic.fr/")}
              >
                <p className="font-bold text-lg">MarketPlace</p>
                {/* <motion.div
                  whileHover={{
                    scale: 1.2,
                  }}
                  animate={{
                    backgroundColor: isHoverM ? "black" : "white",
                  }}
                  onHoverStart={() => setHoverM(true)}
                  onHoverEnd={() => setHoverM(false)}
                  className="w-[40%] h-1/4 flex justify-center items-center border-2 border-black rounded-lg cursor-pointer hover:text-white hover:border-white"
                >
                  <p>Login</p>
                </motion.div> */}
              </motion.div>
            </div>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
}

export default Modal;

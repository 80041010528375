import React, { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import axios from "axios";

import Logo from "../assets/whale-music.png";

export default function Footer({ darkMode }) {
  const [showModal, setShowModal] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loadingForm, setLoadingForm] = useState(false);
  const [t] = useTranslation("common");

  function closeModal() {
    setShowModal(false);
  }

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const notifySuccess = async (string, callbackFinal) => {
    callbackFinal();
    toast.success(string);
    await delay(5900);
  };
  const notifyError = (props) => toast.error(props);

  const sendEmail = (e) => {
    setLoadingForm(true);
    e.preventDefault();
    let data = {
      nameOfAsker: fullName,
      emailOfAsker: email,
      emailSubject: message,
    };
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!email.match(regexEmail)) {
      notifyError("Veullez renseigner un email valide");
      return;
    }
    axios
      .post(
        "http://whalemusicproduction-env.eba-3r3pzmch.us-east-1.elasticbeanstalk.com/contact",
        data
      )
      .then((res) => {
        if (res.status === 201) {
          notifySuccess("Email envoyé !", closeModal);
        }
      })
      .catch((error) => {
        notifyError("Impossible d'envoyer le mail");
      });
    setLoadingForm(false);
  };

  return (
    <section id="footer">
      <div
        className={`${
          darkMode ? "text-white bg-[#160F48]" : "text-black bg-slate-300"
        } py-10 text-tertiary`}
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className="container mx-auto">
          <div className="grid grid-col-2 md:grid-cols-5 gap-2 md:gap-6 text-center md:text-left">
            <div className="col-span-2">
              <img
                src={Logo}
                className="w-12 h-12 mx-auto md:mx-0 lg:mx-0 xl:mx-0 2xl:mx-0"
                alt="logo"
              />
              <p className="mt-3 pr-5">{t("footer.slogan")}</p>
            </div>
            <div>
              <h2 className="font-bold text-2xl">
                {t("footer.section1_title")}
              </h2>
              <p className="mt-6">{t("footer.section1_element1")}</p>
              <p>{t("footer.section1_element2")}</p>
              <p>{t("footer.section1_element3")}</p>
            </div>
            <div>
              <h2 className="font-bold text-2xl">
                {t("footer.section2_title")}
              </h2>
              <p className="mt-6">{t("footer.section2_element1")}</p>
              <p>{t("footer.section2_element2")}</p>
              <p>{t("footer.section2_element3")}</p>
            </div>
            <div className="col-span-2 md:col-span-1 flex flex-col items-center md:items-start">
              <h2 className="font-bold text-2xl">
                {t("footer.section3_title")}
              </h2>
              <button
                data-testid="modEmail"
                className="bg-transparent mt-6 px-8 py-3 border-2 rounded-lg font-semibold transition-all flex items-center gap-2"
                onClick={() => setShowModal(true)}
              >
                {t("footer.section3_element1")}
              </button>
            </div>
          </div>
        </div>
      </div>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <div className="flex flex-col">
                    <h3 className="text-3xl font-semibold">
                      {t("footer.help_modal_title")}
                    </h3>
                    <span className="text-sm flex flex-row items-center">
                      {t("footer.help_modal_description")}{" "}
                      <span
                        role="img"
                        aria-label="nerd"
                        className="text-current text-lg ml-1"
                      >
                        🤓
                      </span>
                    </span>
                  </div>
                  <button
                    data-testid="closeEmail"
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="opacity-70 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      x
                    </span>
                  </button>
                </div>
                <div className="p-4 sm:p-8">
                  <form onSubmit={sendEmail}>
                    <div className="mb-6 form-control">
                      <input
                        autoFocus
                        type="text"
                        placeholder="Nom"
                        id="name"
                        name="name"
                        required
                        data-testid="textName"
                        onChange={(e) => setFullName(e.target.value)}
                        className="
                        form-control w-full
                        rounded-md
                        py-3
                        px-3
                        text-body-color
                        border 
                        border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        peer
                        "
                      />
                    </div>
                    <div className="mb-6 form-control">
                      <input
                        type="email"
                        placeholder="Email"
                        id="email"
                        data-testid="emailSend"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                        className="
                        form-control
                        w-full
                        rounded-md
                        py-3
                        px-3
                        text-body-color
                        border
                        border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        peer
                        "
                      />
                    </div>
                    <div className="mb-6 form-control">
                      <textarea
                        rows="6"
                        placeholder="Message"
                        id="message"
                        name="message"
                        required
                        onChange={(e) => setMessage(e.target.value)}
                        className="
                        form-control
                        w-full
                        rounded-md
                        py-3
                        px-3
                        text-body-color
                        border
                        border-[f0f0f0]
                        resize-none
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        peer
                        "
                      ></textarea>
                    </div>
                    <div className="form-control">
                      <button
                        type="submit"
                        className="
                        w-full
                        text-base
                        text-white
                        bg-gradient-to-r 
                        from-[#0090FF]
                        to-[#CE00FF]
                        rounded-md
                        border border-primary
                        py-2 px-4
                        transition
                        hover:bg-transparent
                        "
                      >
                        {t("footer.help_modal_button")}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </section>
  );
}

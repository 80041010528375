import React from "react";

export default function Button(props) {
  const { text, size, variant } = props;

  const sizeBtn = () => {
    if (size === "lg") {
      return "text-xl px-6 py-3";
    } else {
      return "text-lg px-4 py-2";
    }
  };

  const variantBtn = () => {
    if (variant === "color") {
      return "bg-gradient-to-r from-[#0090FF] to-[#CE00FF] hover:bg-transparent hover:text-primary";
    }
  };

  return (
    <a
      href="#x"
      className={` ${sizeBtn()} ${variantBtn()} dark:text-white tracking-wider rounded-lg transition-all`}
    >
      {text}
    </a>
  );
}
